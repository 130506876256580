<template>
  <div>
    <v-autocomplete
      v-model="currentProfessional"
      :items="professionals"
      :loading="isLoadingProfessionals"
      outlined
      :dense="dense"
      hide-selected
      clearable
      item-text="publicName"
      :placeholder="$t('professionals_selector.professional')"
      :label="
        isNetworkError ? $t('professionals_selector.network_error_short') : $t('professionals_selector.professional')
      "
      :item-value="(returnNumericId) ? 'id' : 'uuid'"
      :error-messages="validationError"
      :disabled="disabled"
      @update:search-input="onChangeSearchVal($event)"
      @click:clear="retry"
    >
      <template v-slot:label>
        <div class="d-flex align-baseline">
          <template v-if="isNetworkError">
            <v-icon color="error" small class="mr-2">
              mdi-alert-circle
            </v-icon>
            <span class="error--text text-body-1">
              {{ $t('professionals_selector.network_error_short') }}
            </span>
          </template>
          <template v-else>
            <span>{{ $t('professionals_selector.professional') }}</span>
          </template>
        </div>
      </template>
      <template slot="item" slot-scope="{ item }">
        <div class="py-1">
          <span class="primary--text text-body-1">
            {{ item.publicName }}
          </span>
          <div>
            <span v-for="specialty in item.specialties" :key="specialty.id" class="caption mr-2">{{
              specialty.name
            }}</span>
          </div>
        </div>
      </template>
      <template v-slot:no-data>
        <div class="professionals-selector-no-data__container primary--text text-body-1 px-4 py-2">
          <ProfessionalsSelectorNetworkError
            v-if="isNetworkError"
            :retry="retrieveProfessionals"
            :loading="isLoadingProfessionals"
          />
          <div v-else>
            <v-progress-circular v-if="isLoadingProfessionals" :width="3" color="primary" indeterminate class="mr-4" />
            <span>{{
              isLoadingProfessionals
                ? $t('professionals_selector.loading_professionals')
                : $t('professionals_selector.not_found_professionals')
            }}</span>
          </div>
        </div>
      </template>
      <template v-slot:append-item>
        <div v-intersect="endIntersect" />
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { useProfessionalsSelector } from '@/use/Professionals/useProfessionalsSelector';
import ProfessionalsSelectorNetworkError from '@/components/professionals/ProfessionalsSelectorNetworkError';
import debounce from '@/utils/debounce';

export default {
  components: {
    ProfessionalsSelectorNetworkError,
  },
  props: {
    professional: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    returnNumericId: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    companyId: {
      type: Number,
      default: 0,
    },
    validationError: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },
  setup(props) {
    const {
      professionals,
      isLastPage,
      currentProfessional,
      isLoadingProfessionals,
      isLoadingCurrentProfessional,
      isNetworkError,
      query,
      getIndex,
      retrieveProfessionals,
      moreProfessionals,
      addProfessionalIfNotExists,
      loadSelectedProfessional,
      reset,
    } = useProfessionalsSelector({ props });

    retrieveProfessionals().then(() => {
      loadSelectedProfessional();
    });

    return {
      professionals,
      isLastPage,
      currentProfessional,
      isLoadingProfessionals,
      isLoadingCurrentProfessional,
      isNetworkError,
      query,
      getIndex,
      retrieveProfessionals,
      moreProfessionals,
      addProfessionalIfNotExists,
      loadSelectedProfessional,
      reset,
    };
  },

  watch: {
    professional(val) {
      if (val) {
        this.addProfessionalIfNotExists(val);
        this.currentProfessional = val.uuid;
      } else {
        this.currentProfessional = null;
      }
    },

    currentProfessional(val) {
      if (val) {
        this.$emit('professionalSelected', val);
      }
    },

    companyId(val) {
      if (val) {
        this.retrieveProfessionals(
          JSON.stringify({
            companyId: val,
          })
        );
      } else {
        this.retrieveProfessionals();
      }
    },
  },
  methods: {
    endIntersect(entries, observer, isIntersecting) {
      if (isIntersecting && !this.isLastPage) {
        this.moreProfessionals();
      }
    },
    onChangeSearchVal(searchString) {
      if (searchString === this.professionals.find(prof => {
          return (this.returnNumericId) ? prof.id === this.currentProfessional : prof.uuid === this.currentProfessional
        })?.publicName
      ) {
        return;
      }

      debounce(() => {
        let params = {};

        if (searchString) {
          params = {
            '*': searchString,
          }
        }

        if (this.companyId) {
          params.companyId = this.companyId;
        }

        this.retrieveProfessionals(JSON.stringify(params));
      }, 600);
    },
    retry() {
      this.reset();
      this.retrieveProfessionals();
    },
  },
};
</script>

<style>
.professionals-selector-no-data__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
