<template>
  <v-layout wrap>
    <v-card id="noService">
      <v-flex xs10 sm8 md8 offset-xs1 offset-sm2 offset-md2 xl6 offset-xl3 class="mt-5">
        <div class="bordered headline grey--text text--darken-1 mt-2 mt-md-5">
          <v-layout wrap class="mt-5">
            <v-flex xs10 sm10 md4 offset-xs1 offset-sm1 offset-md0 class="centered">
              <img v-if="showMensajeriaMsg" src="/static/receta/services/mensajeria.png" width="100%" />
              <img v-if="showVideoconsultaMsg" src="/static/receta/services/videoconsulta.png" width="100%" />
              <img v-if="showAgendaMsg" src="/static/receta/services/agenda.png" width="100%" />
              <img
                v-if="
                  showServiciosMsg ||
                    showPacksMsg ||
                    showCuponesMsg ||
                    showMiwebMsg ||
                    showRecepcionistaMsg ||
                    showDocumentosMsg
                "
                class="img-general"
                src="/static/receta/services/general.png"
                width="100%"
              />
              <img v-if="showHistorialMsg" src="/static/receta/services/historial_clinico.png" width="100%" />
              <img
                v-if="showFacturacionMsg"
                src="/static/receta/services/facturacion.png"
                class="img-general"
                width="100%"
              />
            </v-flex>
            <v-flex xs10 sm10 md7 offset-xs1 offset-md0 class="mb-4">
              <p class="title title-dark mb-5">{{ $t('recipe.premium_title') }}</p>

              <div v-if="showMensajeriaMsg" class="title grey--text text--darken-1 text-content">
                Esta funcionalidad es parte del servicio Premium de la plataforma. Te permitirá chatear con tus
                pacientes con la posibilidad de enviar y recibir informes, analíticas y datos relevantes.<br /><br />
                Si deseas más información sobre el servicio, haz clic en el botón “Más información” y nos pondremos en
                contacto contigo.
              </div>

              <div v-if="showVideoconsultaMsg" class="title grey--text text--darken-1 text-content">
                Esta funcionalidad es parte del servicio Premium de la plataforma y te permitirá conectar con tus
                pacientes a través de videoconsultas encriptadas, ofreciendo la opción de realizar multi-consultas desde
                cualquier dispositivo (ordenador, tablet, móvil).<br /><br />
                Si deseas más información sobre el servicio, haz clic en el botón “Más información” y nos pondremos en
                contacto contigo.
              </div>

              <div v-if="showAgendaMsg" class="title grey--text text--darken-1 text-content">
                Esta funcionalidad es parte del servicio Premium de la plataforma. Se trata de un calendario de citas
                online pensado para facilitar la gestión de videoconsultas y consultas presenciales, donde el paciente
                podrá visualizar los horarios disponibles y seleccionar el más conveniente.<br /><br />
                Si deseas más información sobre el servicio, haz clic en el botón “Más información” y nos pondremos en
                contacto contigo.
              </div>

              <div v-if="showServiciosMsg" class="title grey--text text--darken-1 text-content">
                Esta funcionalidad es parte del servicio Premium de la plataforma. En esta pestaña se configurarán los
                servicios médicos que ofrecerás a tus pacientes. <br /><br />
                Si deseas más información sobre ello, haz clic en el botón “Más información” y nos pondremos en contacto
                contigo.
              </div>

              <div v-if="showPacksMsg" class="title grey--text text--darken-1 text-content">
                Esta funcionalidad es parte del servicio Premium de la plataforma. En esta pestaña se configurarán los
                packs de servicios exclusivos donde podrás añadir videoconsultas y servicios de mensajería que te
                ayudarán a fidelizar a los pacientes. <br /><br />
                Si deseas más información sobre ello, haz clic en el botón “Más información” y nos pondremos en contacto
                contigo.
              </div>

              <div v-if="showCuponesMsg" class="title grey--text text--darken-1 text-content">
                Esta funcionalidad es parte del servicio Premium de la plataforma. En esta pestaña podrás configurar
                códigos de descuento para ofrecer a tus pacientes al utilizar la plataforma de Telemedicina.<br /><br />
                Si deseas más información sobre ello, haz clic en el botón “Más información” y nos pondremos en contacto
                contigo.
              </div>

              <div v-if="showMiwebMsg" class="title grey--text text--darken-1 text-content">
                Esta funcionalidad es parte del servicio Premium de la plataforma. En esta pestaña podrás activar tu
                propia Landing Page para poder promocionar tu consulta online.<br /><br />
                Si deseas más información sobre ello, haz clic en el botón “Más información” y nos pondremos en contacto
                contigo.
              </div>

              <div v-if="showRecepcionistaMsg" class="title grey--text text--darken-1 text-content">
                Esta funcionalidad es parte del servicio Premium de la plataforma. En esta pestaña podrás crear el
                perfil de recepcionista para que pueda gestionar la agenda de tu consulta online.<br /><br />
                Si deseas más información sobre ello, haz clic en el botón “Más información” y nos pondremos en contacto
                contigo.
              </div>

              <div v-if="showHistorialMsg" class="title grey--text text--darken-1 text-content">
                Esta funcionalidad es parte del servicio Premium de la plataforma. En esta pestaña podrás acceder,
                consultar y actualizar el historial clínico digital de tus pacientes.<br /><br />
                Si deseas más información sobre ello, haz clic en el botón “Más información” y nos pondremos en contacto
                contigo.
              </div>

              <div v-if="showDocumentosMsg" class="title grey--text text--darken-1 text-content">
                Esta funcionalidad es parte del servicio Premium de la plataforma. En esta pestaña podrás enviar y
                recibir informes con tus pacientes.<br /><br />
                Si deseas más información sobre ello, haz clic en el botón “Más información” y nos pondremos en contacto
                contigo.
              </div>

              <div v-if="showFacturacionMsg" class="title grey--text text--darken-1 text-content">
                Esta funcionalidad es parte del servicio Premium de la plataforma. En esta pestaña podrás gestionar la
                facturación de tu consulta online.<br /><br />
                Si deseas más información sobre ello, haz clic en el botón “Más información” y nos pondremos en contacto
                contigo.
              </div>
              <br /><br />
              <v-btn :disabled="loading" color="primary" @click="moreInformation">{{
                $t('recipe.more_information')
              }}</v-btn>
            </v-flex>
          </v-layout>
        </div>
      </v-flex>
    </v-card>
  </v-layout>
</template>

<script>
import { mapActions } from 'vuex';
import { sendPremiumInformationEmail, getHeader } from '@/config/config';

export default {
  name: 'NoServiceGranted',
  components: {},
  props: {
    view: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      loading: false,
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
      showVideoconsultaMsg: false,
      showMensajeriaMsg: false,
      showAgendaMsg: false,
      showServiciosMsg: false,
      showPacksMsg: false,
      showCuponesMsg: false,
      showMiwebMsg: false,
      showRecepcionistaMsg: false,
      showHistorialMsg: false,
      showDocumentosMsg: false,
      showFacturacionMsg: false,
    };
  },

  mounted() {
    if (this.view === 'videoconsulta') {
      this.showVideoconsultaMsg = true;
    } else if (this.view === 'mensajeria') {
      this.showMensajeriaMsg = true;
    } else if (this.view === 'agenda') {
      this.showAgendaMsg = true;
    } else if (this.view === 'servicios') {
      this.showServiciosMsg = true;
    } else if (this.view === 'packs') {
      this.showPacksMsg = true;
    } else if (this.view === 'cupones') {
      this.showCuponesMsg = true;
    } else if (this.view === 'miweb') {
      this.showMiwebMsg = true;
    } else if (this.view === 'recepcionista') {
      this.showRecepcionistaMsg = true;
    } else if (this.view === 'historial') {
      this.showHistorialMsg = true;
    } else if (this.view === 'documentos') {
      this.showDocumentosMsg = true;
    } else if (this.view === 'facturacion') {
      this.showFacturacionMsg = true;
    }
  },
  methods: {
    ...mapActions('layout', ['showAlert']),

    moreInformation() {
      this.loading = true;

      this.$http
        .post(sendPremiumInformationEmail, { user_id: this.authUser.id }, { headers: getHeader() })
        .then(res => {
          if (res.status === 200 && res.data.estado === 1) {
            this.showAlertMsg(
              'success',
              '',
              'Hemos recibido su solicitud, en breve le contactaremos para ofrecerle la información que necesita.'
            );
          } else {
            this.showAlertMsg('error', '', this.$t('errors.try_again'));
          }
        })
        .catch(err => {
          const textAlert = err.data && err.data.error ? this.$t(err.data.error) : this.$t('errors.try_again');
          this.showAlertMsg('error', '', textAlert);
          this.e6 = 1;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    showAlertMsg(color, icon, msg) {
      this.showAlert({
        color: color,
        icon: icon,
        message: msg,
      });
    },
  },
};
</script>
<style>
#noService {
  height: 100%;
}

@media (min-width: 576px) {
  #noService {
    height: calc(100vh - 100px) !important;
  }
}

.img-general {
  height: 250px;
  width: auto;
}

@media (max-width: 576px) {
  .text {
    padding: 1em;
  }

  .img-general {
    height: 300px;
    width: auto;
  }
}

.title-dark {
  color: rgba(0, 0, 0, 0.87);
}

.bordered {
  border: 3px solid #d7e8f1;
  border-radius: 20px;
}

.text-content {
  font-size: 18px !important;
  font-weight: 400;
}

.centered {
  text-align: center;
}
</style>
