import {computed, ref} from 'vue';
import { getProfessionals } from '@/views/professionals/professionals.api';

const DEFAULT_ITEMS_PER_PAGE = 20;

export function useProfessionalsSelector({ props }) {
  const query = ref(null);
  const page = ref(1);
  const lastPage = ref(1);
  const professionals = ref([]);
  const currentProfessional = ref(null);

  const isLoadingProfessionals = ref(false);
  const isLoadingCurrentProfessional = ref(false);
  const isNetworkError = ref(false);

  const addProfessionalIfNotExists = professional => {
    const exists = professionals.value.some(el => el.id === professional.id);

    if (!exists) {
      professionals.value.push(professional);
    }
  };

  const retrieveProfessionals = async search => {
    try {
      isNetworkError.value = false;
      isLoadingProfessionals.value = true;
      currentProfessional.value = null;
      page.value = 1;
      lastPage.value = 1;

      let response;

      if (search) {
        query.value = search;
        response = await getProfessionals(
          page.value,
          DEFAULT_ITEMS_PER_PAGE,
          search
        );
      } else {
        response = await getProfessionals();
      }
      professionals.value = response.items;
      lastPage.value = response.meta.lastPage;
    } catch (e) {
      isNetworkError.value = true;
    } finally {
      isLoadingProfessionals.value = false;
    }
  };

  const moreProfessionals = async () => {
    try {
      page.value = page.value + 1;
      isNetworkError.value = false;
      isLoadingProfessionals.value = true;
      let response;

      if (query.value) {
        response = await getProfessionals(
          page.value,
          DEFAULT_ITEMS_PER_PAGE,
          query.value
        );
      } else {
        response = await getProfessionals(
          page.value,
          DEFAULT_ITEMS_PER_PAGE
        );
      }

      professionals.value = [
        ...professionals.value,
        ...response.items
      ];
    } catch (e) {
      isNetworkError.value = true;
    } finally {
      isLoadingProfessionals.value = false;
    }
  };

  const loadSelectedProfessional = () => {
    if (props.professional) {
      addProfessionalIfNotExists(props.professional);
      currentProfessional.value = props.professional.uuid;
    }
  };

  const isLastPage = computed(() => {
    return lastPage.value === page.value;
  });

  const getIndex = (id) => {
    return professionals.value.findIndex((professional) => professional.id === id);
  };

  const reset = () => {
    page.value = 1;
    lastPage.value = 1;
    query.value = null;
  }

  const clearQuery = () => {
    query.value = null;
  }

  return {
    professionals,
    isLastPage,
    currentProfessional,
    isLoadingProfessionals,
    isLoadingCurrentProfessional,
    isNetworkError,
    query,
    getIndex,
    retrieveProfessionals,
    moreProfessionals,
    addProfessionalIfNotExists,
    loadSelectedProfessional,
    reset,
    clearQuery,
  };
}
